.label-header {
  font-size: 20pt;
  padding-bottom: 5pt !important;
  color: #303741 !important;
  font-weight: 400 !important;
}

.label-desc {
  font-size: 14pt;
  font-weight: normal;
  color: #303741 !important;
}

.ui.table thead th {
  background: #d0d0d0 !important;
}

.ui.table,
.ui.table
tbody tr td,
thead tr th,
tfoot tr th
/* thead th,
tfoot tr, */ {
  border-color: #303741 !important;
  border: 1px;
}
/* .table-border-layout {
  border-color: #303741 !important;
} */

.striped > tbody > tr:nth-child(odd) > td,
.striped > tbody > tr:nth-child(odd) > th {
  background-color: #f5f5f5;
}

div[id="root"] {
  background-color: #edf1f6 !important;
}
div[id="footer-pagination"] {
  float: right;
}

.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* Aligning elements left, center and right in flexbox */
  /* float: left; */
  margin-left: 0px;
  padding: auto;
  /* float: right; */
}

.button-container > button {
  flex: 0 48%;
  font-size: 9pt !important;
  font-weight: bold !important;
}

.button-right {
  margin-left: auto !important;
  margin-right: 0 !important;
}

div[class~="sidebar"] > a.active.item {
  background-color: #303741 !important;
  color: white !important;
  border-right: #303741 !important;
}

.com-header-container .header {
  font-size: 24pt !important;
}
.sub-header-container .header {
  font-size: 10pt !important;
  display: block;
  margin-bottom: 10pt !important;
}

.ui.sidebar {
  max-height: calc(100vh - 100px) !important;
}

/* Ellipsis on tables */
.truncate {
  display: table-cell !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.fixed-table {
  width: 100%;
  table-layout: fixed;
  display: table;
}

.list-tr {
  display: table-row;
  /*l&f*/
}
.list-td {
  display: table-cell; /* CHANGED */
  white-space: nowrap;
  /* one line */
  /*l&f*/
  padding: 2px;
}
.ellipsis-preview {
  display: table-cell;
  /*l&f*/
  white-space: nowrap;
  /* one line */
  overflow: hidden;
  /* make text overflow */
  text-overflow: ellipsis;
  /* truncate texT with ... */
}

.list-table {
  display: table;
  border-spacing: 5px;
  width: 100%; /* ADDED */
}

table[id="standard-order-table"] {
  /* background-color: #d0d0d0 !important; */
  border-color: gray;
  border: 1px solid;
}

.max-width-20 {
  width: 20%;
}
.max-width-30 {
  width: 30%;
}
.max-width-10 {
  width: 10%;
}

.progress {
  text-align: center;
}
.progress .circle,
.progress .bar {
  display: block;
  background: #fff;
  width: 30px;
  height: 35px;
  border-radius: 40px;
  vertical-align: top;
}
.progress .bar {
  position: relative;
  width: 3px;
  height: 30px;
  margin: 0 0px 0px 15px;
  border-radius: 0;
  top: 0;
  vertical-align: top;
  left: 0;
}
.progress .circle .label {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 21px;
  border-radius: 32px;
  margin-top: 3px;
  color: #b5b5ba;
  font-size: 17px;
}
.progress .circle .title {
  color: #b5b5ba;
  font-size: 13px;
  line-height: 18px;
  padding-left: 55px;
  display: block;
  width: calc(100vw - 130px);
  margin-top: -25px;
  text-align: left;
}

.progress .circle .title label {
  display: block;
  color: #1678c2;
  font-size: 16px;
  font-weight: bold;
}
/* Done / Active */

.progress .bar.done,
.progress .circle.done {
  background: #eee;
}
.progress .bar.active {
  background: linear-gradient(to right, #eee 40%, #fff 60%);
}
.progress .circle.done .label {
  color: #7f7f7f;
  background: #ffffff;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  border: 2px solid #7f7f7f;
}
.progress .circle.done .title {
  color: #444;
}
.progress .circle.active .label {
  color: #3b6c2f;
  background: #ffffff;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  border: 2px solid #3b6c2f;
}
.progress .circle.active .title {
  color: #444;
}

.details-table tr td {
  padding: 5px;
}

.section-header {
  border-bottom: 2px solid #ebebeb;
}
