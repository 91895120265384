.remove-image {
  position: absolute;
  right: -15px;
  border-radius: 30px 30px;
  background: #db2828;
  width: 20px !important;
  height: 20px !important;
  color: white;
  top: -10px;
  cursor: pointer;
}

.remove-image:hover {
    background: #d01919;
    text-shadow: none;
}