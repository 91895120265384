body {
  background-color: #e2e2e2 !important;
  min-height: 100vh;
  overflow-y: hidden;
}

.ui.segment {
  padding: 0.5em 1em;
  margin: 0.5rem 0;
}

.connected-steps {
  display: flex;
  width: 100%;
  align-items: center;
}

.step-container {
  width: auto;
}

.step {
  border: 3px solid #939598;
  background-color: #939598;
  display: block;
  border-radius: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 20px;
  margin: 3px 3px;
}

.step-active {
  border: 3px solid #4385d0;
  background-color: white;
  display: block;
  border-radius: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 20px;
  margin: 3px 3px;
}

.step-error {
  border: 3px solid #ff0000;
  background-color: white;
  display: block;
  border-radius: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 20px;
  margin: 3px 3px;
}

.step-label {
  text-align: center;
  line-height: 20px;
  margin: 30px -15px;
  size: 16px;
}

.connector {
  flex-grow: 1;
  width: 10px;
  content: "";
  display: block;
  height: 1px;
  background-color: #939598;
  height: 2px;
}
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0ms linear;
}

@media print {
  .print-hidden {
    display: none !important;
  }

  .print-full-width {
    width: 100% !important;
  }

  .pusher {
    transform: none !important;
  }

  .print-expanded {
    display: block !important;
  }
}
